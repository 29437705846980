// TODO: plural support only works for 'Provider' type
export function useOrganizationTypeName(organizationType?: 'Provider' | 'VolunteerCenter' | 'School', plural?: boolean) {
  const { t } = useI18n()
  const isMas = useIsMas()

  if (organizationType == 'Provider') {
    return t(`organization.attributes.${plural ? 'providerTypeNamePlural' : 'providerTypeName'}.` + (isMas ? 'mas' : 'traineeship'))
  }

  // TODO: plural support
  return t('organization.attributes.types.' + organizationType)
}
